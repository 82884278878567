
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
// @use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!


// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

// @use '@angular/material' as mat;
// @import '@angular/material/theming';
// @include mat-core();

// 
// @include mat.core();


@use '@angular/material' as mat;

@include mat.core();

// $my-primary: mat.define-palette(mat.$indigo-palette, 500);
// $my-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// $my-theme: mat.define-light-theme((
//  color: (
//    primary: $my-primary,
//    accent: $my-accent,
//  ),
//  typography: mat.define-typography-config(),
//  density: 0,
// ));

// @include mat.all-component-themes($my-theme);


$dark-primary-text: rgba(black, 0.87);
$light-primary-text: white;



// https://m2.material.io/design/color/the-color-system.html#tools-for-picking-colors
$blue-gray-palette: (
 50: #ECEFF1,
 100: #CFD8DC,
 200: #B0BEC5,
 300: #90A4AE,
 400: #78909C,
 500: #607D8B,
 600: #546E7A,
 700: #455A64,
 800: #37474F,
 900: #263238,

 // ... continues to 900
 contrast: (
   50: $dark-primary-text,
   100: $dark-primary-text,
   200: $dark-primary-text,
   300: $dark-primary-text,
   400: $dark-primary-text,
   500: $light-primary-text,
   600: $light-primary-text,
   700: $light-primary-text,
   800: $light-primary-text,
   900: $light-primary-text,

   // ... continues to 900
 )
);


$blue-gray-triadic-palette: (
 50: #eeebec,
 100: #d4cbcf,
 200: #b8a9b0,
 300: #9d8690,
 400: #896b78,
 500: #765162,
 600: #694958,
 700: #593e4b,
 800: #48323f,
 900: #382632,

 // ... continues to 900
 contrast: (
   50: $dark-primary-text,
   100: $dark-primary-text,
   200: $dark-primary-text,
   300: $dark-primary-text,
   400: $dark-primary-text,
   500: $light-primary-text,
   600: $light-primary-text,
   700: $light-primary-text,
   800: $light-primary-text,
   900: $light-primary-text,

   // ... continues to 900
 )
);




$purple-palette: (
 50: #7c4efc,
 100: #764bee,
 200: #7349df,
 300: #6f48d4,
 400: #4d3292,
 500: #4f3493,
 600: #3e2973,
 700: #352F44,
 800: #33215e,
 900: #0a0612,

 // ... continues to 900
 contrast: (
   50: $dark-primary-text,
   100: $dark-primary-text,
   200: $dark-primary-text,
   300: $dark-primary-text,
   400: $dark-primary-text,
   500: $light-primary-text,
   600: $light-primary-text,
   700: $light-primary-text,
   800: $light-primary-text,
   900: $light-primary-text,

   // ... continues to 900
 )
);




// $gold-palette: (
//  50: #A38831,
//  100: #A38831,
//  200: #B2993E,
//  300: #B2993E,
//  400: #D5C264,
//  500: #D5C264,
//  600: #E7D981,
//  700: #E7D981,
//  800: #F8EFAE,
//  900: #FDF9DC,


//  // ... continues to 900
//  contrast: (
//    50: $dark-primary-text,
//    100: $dark-primary-text,
//    200: $dark-primary-text,
//    300: $dark-primary-text,
//    400: $dark-primary-text,
//    500: $light-primary-text,
//    600: $light-primary-text,
//    700: $light-primary-text,
//    800: $light-primary-text,
//    900: $light-primary-text,
//    A100: $dark-primary-text,
//    A200: $dark-primary-text,
//    A300: $dark-primary-text,
//    A400: $dark-primary-text

//    // ... continues to 900
//  )
// );



$gold-palette: (
 50: #A38831,
 100: #A38831,
 200: #A38831,
 300: #A38831,
 400: #A38831,
 500: #A38831,
 600: #A38831,
 700: #A38831,
 800: #A38831,
 900: #A38831,


 // ... continues to 900
 contrast: (
   50: $dark-primary-text,
   100: $dark-primary-text,
   200: $dark-primary-text,
   300: $dark-primary-text,
   400: $dark-primary-text,
   500: $light-primary-text,
   600: $light-primary-text,
   700: $light-primary-text,
   800: $light-primary-text,
   900: $light-primary-text,
   A100: $dark-primary-text,
   A200: $dark-primary-text,
   A300: $dark-primary-text,
   A400: $dark-primary-text

   // ... continues to 900
 )
);



$client-primary: mat.define-palette($blue-gray-palette);
$client-accent: mat.define-palette($blue-gray-triadic-palette);
// , A200, A100, A400
// The warn palette is optional (defaults to red).
$client-warn: mat.define-palette(mat.$red-palette);


$my-primary: mat.define-palette(mat.$indigo-palette, 500);
$my-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

$my-primary: mat.define-palette(mat.$indigo-palette, 500);



$custom-success: mat.define-palette(mat.$green-palette);


// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$client-theme: mat.define-dark-theme((
  color: (
    primary: $client-primary,
    accent: $client-accent,
    warn: $client-warn,
    color:(
      success: $custom-success
    )
  ),
  typography: mat.define-typography-config(),
  density: 0,
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.



@include mat.all-component-themes($client-theme);

.mat-success {
  background-color: mat.get-color-from-palette($gold-palette, 500);
  color: mat.get-color-from-palette($gold-palette, 500-contrast);
}

// .mat-danger {
//   background-color: material.get-color-from-palette($custom-danger, 500);
//   color: material.get-color-from-palette($custom-danger, 500-contrast);
// }